import jsUtils from '@pernod-ricard-global-cms/jsutils';

function toggleSubmit() {
  const form = document.querySelector('.competition_form');
  const formValidity = form.checkValidity();
  const submitButton = form.querySelector('button.competition-submit-btn');
  const emailField = document.querySelector('input[type="email"]');
  const fileFields = document.querySelectorAll('input[type="file"]');
  if (
    formValidity &&
    jsUtils.isEmailValid(emailField.value) &&
    hasRequiredFields(fileFields)
  ) {
    if (!submitButton.classList.contains('valid')) {
      submitButton.classList.add('valid');
    }
    submitButton.disabled = false;
  } else {
    submitButton.classList.remove('valid');
    submitButton.disabled = true;
  }
}

function hasRequiredFields(fileFields) {
  if (fileFields.length === 0) {
    return true;
  }
  for (let i = 0; i < fileFields.length; ++i) {
    if (fileFields[i].files.length > 0) {
      return true; // has at least one file
    }
  }
  return false;
}

export default function prCompetitionJs(options = {}) {
  try {
    const form = document.querySelector('.competition_form');
    if (form) {
      const formElements = form.querySelectorAll('input');
      formElements.forEach((element) => {
        element.addEventListener('change', toggleSubmit);

        if (element.type === 'checkbox') {
          element.addEventListener('change', function () {
            // only toggle active for MCQ section on change
            if (element.closest('._multiple-choice-area') !== null) {
              const labels = document.querySelectorAll('._option label');
              [].forEach.call(labels, (el) => {
                el.classList.remove('_option_checked');
              });
            }

            const label = element.parentElement;
            if (this.checked) {
              label.classList.add('_option_checked');
            }
          });
        }
      });
    }
  } catch (error) {
    console.error(error);
  }
}
