/**
 * Get Cookie
 * @param {string}
 * @return {string || boolean}
 */
const getCookie = (cookieName) => {
  const name = `${cookieName}=`;
  const cookies = document.cookie.split(';');

  /* eslint-disable-next-line no-plusplus */
  for (let i = 0; i < cookies.length; i++) {
    let cookie = cookies[i];

    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }

    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }

  return false;
};

/**
 * Check if user is logged in.
 * @return {boolean} true | false
 */
const loggedInUser = () => {
  if (
    getCookie('STYXKEY_solas_user') !== false &&
    getCookie('STYXKEY_solas_user') !== ''
  ) {
    return true;
  }

  return false;
};

const skipSignUp = () => {
  let skip = (getCookie('skipSignUp') === 'true') ? true : false;
  return skip;
};

/**
 * Display Modal
 * @param {string}
 */
const displayModal = (element, htmlElement) => {
  const { modalName, popUpDelay } = element.dataset;
  const thisModal = document.querySelector(
    `.modal[data-modal-name="${modalName}"]`
  );
  const container = thisModal.querySelector('.modal__container');

  const timeout =
    popUpDelay !== undefined ? parseFloat(popUpDelay) * 1000 : 800;

  if (modalName === 'sign-in' && loggedInUser() || skipSignUp()) {
    thisModal.remove();
    return;
  }

  setTimeout(() => {
    container.classList.remove('hide-modal');
    thisModal.classList.add('modal--visible');
    container.classList.add('display-modal');
    htmlElement.classList.add('modal-active');
  }, timeout);
};

/**
 * Hide Modal
 * @param {string}
 */
const hideModal = (element, htmlElement) => {
  const activeModal = document.querySelector('.modal--visible');
  const container = activeModal.querySelector('.modal__container');

  container.classList.add('hide-modal');

  document.getElementsByTagName('html')[0].classList.remove('disable-scroll');
  htmlElement.classList.remove('modal-active');

  setTimeout(() => {
    activeModal.classList.remove('modal--visible');
    container.classList.remove('display-modal');
  }, 800);
};

export default function modalJs() {
  try {
    // Close modal when a close button in the modal has been pressed
    const modals = document.querySelectorAll('.modal-js');
    const modalTriggers = document.querySelectorAll('.modal__trigger');
    const modalAutoTriggers = document.querySelectorAll('.modal-auto-trigger');
    const modalCloseButtons = document.querySelectorAll('.modal__close');
    const htmlElement = document.documentElement;
    const sortedModals = Array.from(modals).sort(
      (a, b) => a.dataset.order - b.dataset.order
    );

    // Move all modals to bottom of body so that position
    // of parent doesn't become an issue
    sortedModals.forEach((modal) => {
      document.body.appendChild(modal);
    });

    modalAutoTriggers.forEach((element) => {
      displayModal(element, htmlElement);
    });

    modalTriggers.forEach((element) => {
      element.addEventListener('click', (event) => {
        event.preventDefault();
        displayModal(event.target, htmlElement);
      });
    });

    modalCloseButtons.forEach((closeButton) => {
      closeButton.addEventListener('click', (event) => {
        event.preventDefault();
        // Remove instead of toggle because it might activate inactive modals on the page.
        hideModal(event, htmlElement);
      });
    });
  } catch (error) {
    console.error(error);
  }
}
