// Multilingual markets language toggle.

export default function mmLangToggle() {
  try {
    const $mmToggle = document.querySelectorAll('.mm-toggle');

    if ($mmToggle.length > 0) {
      $mmToggle.forEach(($el) => {
        const $mmToggleContainer = $el.querySelector('.mm-toggle__container');
        const $mmToggleItem = $el.querySelectorAll('.mm-toggle__container--item');
        const isEnabled = $mmToggleContainer.dataset.mmEnabled;

        $mmToggleItem.forEach(($el) => {
          $el.addEventListener('click', (event) => {
            event.preventDefault();

            isEnabled === 'active' ? 
              $mmToggleContainer.dataset.mmEnabled = '' :
              $mmToggleContainer.dataset.mmEnabled = 'active';

            location.href = event.target.parentNode.href;
          });
        });
      });
    }
    
  } catch (error) {
    console.error(error);
  }
}
