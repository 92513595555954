import jsUtils from '@pernod-ricard-global-cms/jsutils';
import lazyloader from '@pernod-ricard-global-cms/cbllazyloader';
import assets from 'Assets/js/modules/jsAssets';
import siteHeaderJs from 'Assets/js/components/site-header';
import mmLangToggle from 'Assets/js/components/snippets/multilingual-markets-lang-selector';
import prCompetitionJs from 'Assets/js/components/pr-competition';
import colourPalettes from "@pernod-ricard-global-cms/cblcolourpalettes";
import siteModal from 'Assets/js/modules/modal';
// import featuredproduct from 'Assets/js/components/blocks/featured-product';
window.addEventListener('DOMContentLoaded', () => {
    var _a;
    jsUtils.checkDevice();
    console.log("Hello world!!!");
    // const header = document.querySelector('.site-header');
    // siteHeader({ block: header });
    // Check if current theme is Gutenberg template
    const isGutenberg = (_a = globalThis === null || globalThis === void 0 ? void 0 : globalThis.GLOBAL_THEME) === null || _a === void 0 ? void 0 : _a.gutenberg;
    if ("0" === isGutenberg) {
        // Load non-Gutenberg stuff
        // Athena colour palette
        const colourData = globalThis.colourconfig;
        if (colourData) {
            colourPalettes(colourData);
        }
        // Lazy loader
        lazyloader.options.assetArray = assets.dynamicAssets;
        // lazyloader.options.debugLogMessages = true;
        lazyloader.options.filePath = "js/blocks/";
        lazyloader.lazyloaderInit();
    }
    siteHeaderJs();
    mmLangToggle();
    prCompetitionJs();
    siteModal();
    const appHeight = () => {
        const doc = document.documentElement;
        doc.style.setProperty('--screen-height', `${window.innerHeight}px`);
    };
    window.addEventListener('resize', appHeight);
    appHeight();
    // Function to set a custom property with the actual viewport height.
    function setVH() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
    // Initial set.
    setVH();
    // Update the value when the window is resized.
    window.addEventListener('resize', () => {
        setVH();
    });
});
